"use strict";
// This file contains type definitions for the various kinds of data that we use
// throughout unlock-app.
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyStatus = exports.HooksName = exports.HookType = exports.EventStatus = exports.TransactionStatus = exports.TransactionType = void 0;
// A bug in eslint causes it to think that this exported enum is "unused". So
// disable eslint for that declaration until they fix it. TODO: follow up on this.
var TransactionType;
(function (TransactionType) {
    TransactionType["LOCK_CREATION"] = "LOCK_CREATION";
    TransactionType["KEY_PURCHASE"] = "KEY_PURCHASE";
    TransactionType["WITHDRAWAL"] = "WITHDRAWAL";
    TransactionType["UPDATE_KEY_PRICE"] = "UPDATE_KEY_PRICE";
})(TransactionType || (exports.TransactionType = TransactionType = {}));
var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["SUBMITTED"] = "submitted";
    TransactionStatus["PENDING"] = "pending";
    TransactionStatus["MINED"] = "mined";
    TransactionStatus["STALE"] = "stale";
    TransactionStatus["FAILED"] = "failed";
    TransactionStatus["NONE"] = "";
})(TransactionStatus || (exports.TransactionStatus = TransactionStatus = {}));
// Event deployment status
var EventStatus;
(function (EventStatus) {
    EventStatus["PENDING"] = "pending";
    EventStatus["DEPLOYED"] = "deployed";
})(EventStatus || (exports.EventStatus = EventStatus = {}));
var HookType;
(function (HookType) {
    HookType["CUSTOM_CONTRACT"] = "CUSTOM_CONTRACT";
    HookType["PASSWORD"] = "PASSWORD";
    HookType["PROMOCODE"] = "PROMOCODE";
    HookType["PROMO_CODE_CAPPED"] = "PROMO_CODE_CAPPED";
    HookType["PASSWORD_CAPPED"] = "PASSWORD_CAPPED";
    HookType["CAPTCHA"] = "CAPTCHA";
    HookType["GUILD"] = "GUILD";
    HookType["GITCOIN"] = "GITCOIN";
    HookType["ADVANCED_TOKEN_URI"] = "ADVANCED_TOKEN_URI";
    HookType["ALLOW_LIST"] = "ALLOW_LIST";
})(HookType || (exports.HookType = HookType = {}));
exports.HooksName = [
    'onKeyPurchaseHook',
    'onKeyCancelHook',
    'onValidKeyHook',
    'onTokenURIHook',
    'onKeyTransferHook',
    'onKeyExtendHook',
    'onKeyGrantHook',
];
var KeyStatus;
(function (KeyStatus) {
    KeyStatus["NONE"] = "none";
    KeyStatus["CONFIRMING"] = "confirming";
    KeyStatus["CONFIRMED"] = "confirmed";
    KeyStatus["EXPIRED"] = "expired";
    KeyStatus["VALID"] = "valid";
    KeyStatus["SUBMITTED"] = "submitted";
    KeyStatus["PENDING"] = "pending";
    KeyStatus["FAILED"] = "failed";
})(KeyStatus || (exports.KeyStatus = KeyStatus = {}));
